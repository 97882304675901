import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import styles from "./ComingSoon.module.css";

const ComingSoon = () => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  function calculateTimeLeft() {
    const difference = +new Date("2023-07-01") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  return (
    <div className={styles.container}>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className={styles.content}
      >
        <motion.h1
          className={styles.title}
          initial={{ scale: 0.5 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          Solylock
        </motion.h1>
        <motion.p
          className={styles.subtitle}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          Something amazing is coming soon
        </motion.p>
        <motion.div
          className={styles.countdown}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.8 }}
        >
          <div className={styles.timeUnit}>
            <span className={styles.timeValue}>{timeLeft.days}</span>
            <span className={styles.timeLabel}>days</span>
          </div>
          <div className={styles.timeUnit}>
            <span className={styles.timeValue}>{timeLeft.hours}</span>
            <span className={styles.timeLabel}>hours</span>
          </div>
          <div className={styles.timeUnit}>
            <span className={styles.timeValue}>{timeLeft.minutes}</span>
            <span className={styles.timeLabel}>minutes</span>
          </div>
          <div className={styles.timeUnit}>
            <span className={styles.timeValue}>{timeLeft.seconds}</span>
            <span className={styles.timeLabel}>seconds</span>
          </div>
        </motion.div>
        <motion.form
          className={styles.form}
          onSubmit={(e) => e.preventDefault()}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
        >
          <input
            type="email"
            placeholder="Enter your email"
            className={styles.input}
          />
          <button type="submit" className={styles.button}>
            Notify Me
          </button>
        </motion.form>
      </motion.div>
    </div>
  );
};

export default ComingSoon;
