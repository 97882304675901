import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HomePage, Auth } from "./Components/index.js";
import ComingSoon from "./Components/ComingSoon.jsx";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={ComingSoon} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
